


































import { defineProps, defineEmits, withDefaults } from "@vue/runtime-dom";
import { ComponentPublicInstance, ref } from "@vue/composition-api";
interface Props {
  groups: string[];
  type: string;
  namespace: string;
}
const __sfc_main = {};
__sfc_main.props = {
  groups: {
    key: "groups",
    required: true,
    type: Array
  },
  type: {
    key: "type",
    required: false,
    type: String,
    default: "is-info"
  },
  namespace: {
    key: "namespace",
    required: false,
    type: String,
    default: "common.enumList"
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const emit = __ctx.emit;
  const currentGroup = ref<string | null>(null);
  const tooltip = ref<ComponentPublicInstance<any> | null>(null);
  function onResetCurrentGroup() {
    if (currentGroup.value) {
      currentGroup.value = null;
      emit("change", null);
    }
  }
  function selectGroup(group: string) {
    tooltip.value.$refs.tippy.tip.hide();
    currentGroup.value = group;
    emit("change", group);
  }
  return {
    currentGroup,
    tooltip,
    onResetCurrentGroup,
    selectGroup
  };
};
export default __sfc_main;
