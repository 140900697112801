import { render, staticRenderFns } from "./GroupsSelectDropdown.vue?vue&type=template&id=89853996&scoped=true"
import script from "./GroupsSelectDropdown.vue?vue&type=script&lang=ts"
export * from "./GroupsSelectDropdown.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89853996",
  null
  
)

export default component.exports